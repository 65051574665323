// - - - - - - - - - - - - - - - - - -

// Page - service

// - - - - - - - - - - - - - - - - - -

.page-service {
	.all-posts {
		margin-bottom: 48px;

		.post-new {
			margin-bottom: 16px;

			.grid {
				grid-gap: 24px;
			}

			.post__image {
				width: calc(100% + 60px);
				height: 288px;
				position: relative;
				margin-left: -30px;
				margin-bottom: 0;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				border-radius: 20px;
				box-shadow: 0px 0px 20px $accent-color;

				&--new {
					&::before {
						content: "★";
						display: flex;
						justify-content: center;
						align-items: center;
						width: 48px;
						height: 48px;
						background-color: $accent-color;
						color: white;
						border: 2px solid white;
						border-radius: 100%;

						font-family: $title-font;
						font-weight: $bold-weight;
						font-size: 30px;

						position: absolute;
						top: -28px;
						right: 30px;

						transform: rotate(30deg);
					}
				}
			}

			.post__content-wrap {
				justify-self: center;
				align-self: center;
			}

			.post__content {
				max-width: 456px;
			}

			.post__date {
				margin-bottom: 8px;
			}

			.post__title {
				margin-bottom: 12px;
			}

			.post__description {
				margin-bottom: 16px;
			}
		}

		.posts__list {
			grid-row-gap: 56px;
		}

		.post {
			text-align: center;
		}

		.post:hover .post__image,
		.post:focus .post__image {
			transition: ease-in-out 0.3s;
			transform: scale(1.05);
		}
	}

	.pagination {
		margin-bottom: 48px;

		.wrap {
			display: flex;
			justify-content: center;
		}

		a {
			margin: 0 10px;
		}
	}

	@include mq(tabletp) {
		.all-posts {
			margin-bottom: 80px;

			.posts__list {
				grid-template-columns: 1fr 1fr;
			}

			.post-new {
				grid-column: 1/3;

				.grid {
					grid-template-columns: 1fr 1fr;
					grid-gap: 30px;
				}

				.post__image {
					width: 100%;
					margin-left: 0;

					&::before {
						width: 56px;
						height: 56px;
						right: -28px;
						font-size: 30px;
						border: 3px solid white;
					}
				}

				.post__title {
					margin-bottom: 24px;
				}

				.post__description {
					margin-bottom: 24px;
				}
			}
			.post:nth-child(even) .grid .post__content-wrap {
				text-align: center;
				grid-column: 1;
				grid-row: 1;
			}
		}

		.pagination a {
			margin: 0 20px;
		}
	}

	@include mq(tabletl) {
		.all-posts {
			margin-bottom: 100px;

			.post-new {
				grid-column: 1/4;

				.post__image {
					height: 386px;
				}
			}

			.posts__list {
				grid-template-columns: 1fr 1fr 1fr;
				grid-row-gap: 80px;
			}
		}
	}
}

.post {
	display: flex;
}

.underline {
	text-decoration: underline;
}

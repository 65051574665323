// - - - - - - - - - - - - - - - - - -

// Section - Hero

// - - - - - - - - - - - - - - - - - -

.hero {
	position: relative;
	padding: 40px 0 80px;

	.wrap {
		grid-gap: 0;
	}

	.title {
		margin-bottom: 24px;
		max-width: 380px;
	}

	.title .subtitle {
		margin-bottom: 8px;
	}

	.intro {
		position: relative;
		max-width: 340px;
		text-align: center;
		padding: 10px;
	}

	.hero__cta {
		position: absolute;
		left: 25vw;
		bottom: -70px;
	}

	@include mq(tabletp) {
		.wrap {
			grid-template-columns: 1fr 1fr;
			align-items: center;
		}

		.title {
			margin-bottom: 0;
			max-width: none;
		}

		.intro {
			align-self: end;
			max-width: 525px;
			text-align: center;
			padding: 10px;
		}

		.hero__cta {
			display: none;
		}
	}
}

.hero__background {
	display: block;
	position: absolute;
	top: 0px;
	left: 50%;
	z-index: -1;
	max-width: 190%;
}

@include mq(tabletp) {
	display: block;
	width: 300px;
	height: 300px;
	object-fit: none;
}

// - - - - - - - - - - - - - - - - - -

// Footer

// - - - - - - - - - - - - - - - - - -

.footer {
	@include transition(all 0.4s ease-in-out 0.8s);
	padding: 64px 0;

	.footer__logo {
		justify-self: center;
	}

	.footer__title {
		font-family: $title-font;
		font-size: $sitetitle-small;
		font-weight: $sitetitle-weight;
		letter-spacing: $sitetitle-spacing;
		line-height: 1;
		color: $text-light-color;

		@include mq(tabletp) {
			font-size: $sitetitle-medium;
		}

		@include mq(laptop) {
			font-size: $sitetitle-medium;
		}

		&:hover {
			color: $accent-color;
		}
	}

	.footer__copyright {
		@include mq(tabletp) {
			justify-self: center;
			align-self: center;
			text-align: center;
		}

		p {
			margin-bottom: 10px;
		}
	}

	@include mq(tabletp) {
		padding: 80px 0;

		.wrap {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			align-content: center;
			flex-wrap: nowrap;
			width: 80%;
		}

		.footer__logo {
			justify-self: start;
		}

		.footer__copyright {
			justify-self: center;
		}
	}
}

// - - - - - - - - - - - - - - - - - -

// Page

// - - - - - - - - - - - - - - - - - -

.header--single {
	padding: 40px 0 48px;

	.header__title {
		margin-bottom: 16px;
	}

	.header__subtitle {
		text-align: center;
	}

	@include mq(tabletp) {
		padding: 56px 0 120px;
	}

	.full-width {
		text-align: center;
	}
}

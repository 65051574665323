// - - - - - - - - - - - - - - - - - -

// Plugins

// - - - - - - - - - - - - - - - - - -

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Fluidbox

@-webkit-keyframes a {
  0% {
    -webkit-transform: translate(-50%, -50%) rotateX(0) rotateY(0);
    transform: translate(-50%, -50%) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) rotateX(-180deg) rotateY(0);
    transform: translate(-50%, -50%) rotateX(-180deg) rotateY(0);
  }
  to {
    -webkit-transform: translate(-50%, -50%) rotateX(-180deg) rotateY(-180deg);
    transform: translate(-50%, -50%) rotateX(-180deg) rotateY(-180deg);
  }
}
@keyframes a {
  0% {
    -webkit-transform: translate(-50%, -50%) rotateX(0) rotateY(0);
    transform: translate(-50%, -50%) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: translate(-50%, -50%) rotateX(-180deg) rotateY(0);
    transform: translate(-50%, -50%) rotateX(-180deg) rotateY(0);
  }
  to {
    -webkit-transform: translate(-50%, -50%) rotateX(-180deg) rotateY(-180deg);
    transform: translate(-50%, -50%) rotateX(-180deg) rotateY(-180deg);
  }
}
.fluidbox {
  outline: none;
}
.fluidbox__overlay {
  background-color: hsla(0, 0%, 100%, 0.85);
  cursor: pointer;
  cursor: zoom-out;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: -100%;
  left: 0;
  bottom: -100%;
  right: 0;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.fluidbox--opened .fluidbox__overlay {
  pointer-events: auto;
}
.fluidbox__wrap {
  background-position: center center;
  background-size: cover;
  margin: 0 auto;
  position: relative;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.fluidbox--closed .fluidbox__thumb,
.fluidbox__thumb {
  -webkit-transition: opacity 0s ease-in-out 0s;
  transition: opacity 0s ease-in-out 0s;
}
.fluidbox__ghost {
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  -webkit-transition: opacity 0s 0s, -webkit-transform 0.25s 0s;
  transition: opacity 0s 0s, -webkit-transform 0.25s 0s;
  transition: opacity 0s 0s, transform 0.25s 0s;
  transition: opacity 0s 0s, transform 0.25s 0s, -webkit-transform 0.25s 0s;
}
.fluidbox--opened .fluidbox__ghost {
  cursor: pointer;
  cursor: zoom-out;
}
.fluidbox--closed .fluidbox__ghost {
  -webkit-transition: opacity 0s 0.25s, -webkit-transform 0.25s 0s;
  transition: opacity 0s 0.25s, -webkit-transform 0.25s 0s;
  transition: opacity 0s 0.25s, transform 0.25s 0s;
  transition: opacity 0s 0.25s, transform 0.25s 0s, -webkit-transform 0.25s 0s;
}
.fluidbox__loader {
  opacity: 0;
  -webkit-perspective: 200px;
  perspective: 200px;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.fluidbox__loader:before {
  background-color: hsla(0, 0%, 100%, 0.85);
  content: "";
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20%;
  padding-bottom: 20%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.fluidbox--loading .fluidbox__loader {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease-in-out 0.25s;
  transition: opacity 0.25s ease-in-out 0.25s;
}
.fluidbox--loading .fluidbox__loader:before {
  -webkit-animation: a 1s 0s infinite ease-in-out forwards;
  animation: a 1s 0s infinite ease-in-out forwards;
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Owl Carousel

.owl-carousel {
  display: none;
  // width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 500ms;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

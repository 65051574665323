// - - - - - - - - - - - - - - - - - -

// Section - Partners

// - - - - - - - - - - - - - - - - - -

.partner_image {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	.w30 {
		width: 30%;
	}
	img {
		border-radius: 20px;
		box-shadow: 0px 0px 10px 3px $accent-color;
		height: 130px;
		margin: 10px;
		min-width: 80vw;
		object-fit: contain;
	}
	.sandler {
		object-fit: none;
	}
	@include mq(phonel) {
		img {
			width: auto !important;
			min-width: 0;
		}
		.sandler {
			object-fit: contain;
		}
	}
}

.partner_title {
	text-align: center;
	padding-bottom: 40px;
}

.bluebg-a {
	background: linear-gradient(68deg, #0354d0 0%, #1c7cfe 100%);
}
.bluebg-n {
	background-color: #0546a6;
}
.bluebg-s {
	background-color: #0c304b;
}

.whitebg {
	background-color: white;
}
.greybg {
	background-color: #6d6e71;
}
.greenbg {
	background-color: #01b236;
}

.veeam {
	size: 25%;
}

.cox {
	padding: 15px;
}
